/// <reference path="../d3v3" /> 

import * as React from 'react';
import Datamaps from './Datamaps';
import { CountrySummary } from './Analytics';
import * as d3 from 'd3';

export interface Props {
  countrySummaries: Map<string, CountrySummary>;
  onCountryClick: (arg: { id: string }) => any;
  countryValue: (arg: CountrySummary) => number;
  colourStart: string;
  colourEnd: string;
}

function WorldMap(props: Props) {
  const temp = Array.from(props.countrySummaries.values()).map(props.countryValue);
  const maxValue = Math.max.apply(null, temp);  

  const paletteScale = d3.scale.sqrt()
                         .domain([0, maxValue])
                         .range([props.colourStart, props.colourEnd]);

  const countryKeys = ['AFG', 'AGO', 'ALB', 'ARE', 'ARG', 'ARM', 'ATA', 'ATF', 'AUS', 'AUT', 'AZE', 'BDI', 'BEL', 'BEN', 'BFA', 'BGD', 'BGR', 'BHS', 'BIH', 'BLR', 'BLZ', 'BOL', 'BRA', 'BRN', 'BTN', 'BWA', 'CAF', 'CAN', 'CHE', 'CHL', 'CHN', 'CIV', 'CMR', 'COD', 'COG', 'COL', 'CRI', 'CUB', 'CYP', 'CZE', 'DEU', 'DJI', 'DNK', 'DOM', 'DZA', 'ECU', 'EGY', 'ERI', 'ESP', 'EST', 'ETH', 'FIN', 'FJI', 'FLK', 'FRA', 'GUF', 'GAB', 'GBR', 'GEO', 'GHA', 'GIN', 'GMB', 'GNB', 'GNQ', 'GRC', 'GRL', 'GTM', 'GUY', 'HND', 'HRV', 'HTI', 'HUN', 'IDN', 'IND', 'IRL', 'IRN', 'IRQ', 'ISL', 'ISR', 'ITA', 'JAM', 'JOR', 'JPN', 'KAZ', 'KEN', 'KGZ', 'KHM', 'KOR', 'KWT', 'LAO', 'LBN', 'LBR', 'LBY', 'LKA', 'LSO', 'LTU', 'LUX', 'LVA', 'MAR', 'MDA', 'MDG', 'MEX', 'MKD', 'MLI', 'MMR', 'MNE', 'MNG', 'MOZ', 'MRT', 'MWI', 'MYS', 'NAM', 'NCL', 'NER', 'NGA', 'NIC', 'NLD', 'NOR', 'NPL', 'NZL', 'OMN', 'PAK', 'PAN', 'PER', 'PHL', 'PNG', 'POL', 'PRI', 'PRK', 'PRT', 'PRY', 'QAT', 'ROU', 'RUS', 'RWA', 'ESH', 'SAU', 'SDN', 'SSD', 'SEN', 'SLB', 'SLE', 'SLV', 'SOM', 'SRB', 'SUR', 'SVK', 'SVN', 'SWE', 'SWZ', 'SYR', 'TCD', 'TGO', 'THA', 'TJK', 'TKM', 'TLS', 'TTO', 'TUN', 'TUR', 'TWN', 'TZA', 'UGA', 'UKR', 'URY', 'USA', 'UZB', 'VEN', 'VNM', 'VUT', 'PSE', 'YEM', 'ZAF', 'ZMB', 'ZWE'];
  let data = {};
  for (const i of Object.keys(countryKeys)) {
    data[countryKeys[i]] = { activations: 0
                           , activeKeys: 0
                           , activeDevices: 0
                           , value: 0
                           , fillColor: props.colourStart
                           };
  }

  const d = props.countrySummaries;
  if (d) {
    d.forEach((v, k, map) => {
      const VALUE = props.countryValue(v);
      data[k] = { activations: v.requests
                , activeKeys: v.activeKeys
                , activeDevices: v.activeDevices
                , value: VALUE
                , fillColor: paletteScale(VALUE)
                };
    });

  }

  const onCountryClick = props.onCountryClick;
  return (
    <Datamaps
      scope="world"
      projection="mercator"
      fills={{ defaultFill: props.colourStart }}
      data={data}
      geographyConfig={{
        popupTemplate: function(geo: any, data: any) {
          return [ '<div class="hoverinfo"><strong>'
                 , geo.properties.name , '</strong><br />'
                 , 'Total requests: ' , (data ? data.activations : 0), '<br />'
                 , 'Active keys: ' , (data ? data.activeKeys : 0), '<br />'
                 , 'Active devices: ' , (data ? data.activeDevices : 0), '<br />'
                 , '</div>'
                 ].join('');
        }
      }}
      done={function(datamap: any) {
        datamap.svg.selectAll('.datamaps-subunit').on('click', onCountryClick);
      }}
    />
  );
}

export default WorldMap;
