import * as React from 'react';
import Datamaps from 'datamaps';

class Datamap extends React.Component<any, any> {
  container: HTMLElement | null;
  map: any;

  constructor(props: any) {
    super(props);
    this.container = null
    this.resizeMap = this.resizeMap.bind(this);
  }

  componentDidMount() {
    if (this.props.responsive) {
      window.addEventListener('resize', this.resizeMap);
    }
    this.drawMap();
  }

  componentWillReceiveProps(newProps: any) {
    if (propChangeRequiresMapClear(this.props, newProps)) {
      this.clear();
    }
  }

  componentDidUpdate() {
    this.drawMap();
  }

  componentWillUnmount() {
    this.clear();
    if (this.props.responsive) {
      window.removeEventListener('resize', this.resizeMap);
    }
  }

  clear() {
    if (this.container) {
      for (const child of Array.from(this.container.childNodes)) {
        this.container.removeChild(child);
      }
    }

    delete this.map;
  }

  drawMap() {
    const {
      arc,
      arcOptions,
      bubbles,
      bubbleOptions,
      data,
      graticule,
      labels,
      updateChoroplethOptions,
      ...props
    } = this.props;

    let map = this.map;

    if (!map) {
      map = this.map = new Datamaps({
        ...props,
        data,
        element: this.container
      });
    } else {
      map.updateChoropleth(data, updateChoroplethOptions);
    }

    if (arc) {
      map.arc(arc, arcOptions);
    }

    if (bubbles) {
      map.bubbles(bubbles, bubbleOptions);
    }

    if (graticule) {
      map.graticule();
    }

    if (labels) {
      map.labels();
    }
  }

  resizeMap() {
    this.map.resize();
  }

  render() {
    const style = {
//      height: '100%',
      height: '500px',
      position: 'relative',
      width: '100%',
      ...this.props.style
    };
    return (
      <div
        ref={(input) => { this.container = input; }}
        style={style} 
      />);
  }
}

export default Datamap;

const MAP_CLEARING_PROPS = [
  'height', 'scope', 'setProjection', 'width'
];

function propChangeRequiresMapClear(oldProps: any, newProps: any) {
  return MAP_CLEARING_PROPS.some((key) =>
    oldProps[key] !== newProps[key]
  );
}
