import * as React from 'react';
import { LicenseKey, Anomaly } from './Analytics';

export interface Props {
  allAnomalies: Anomaly[]; 
  selectedAnomaly: string;

  timeRange?: [Date, Date];

  selectedCountries: Set<string>;
  filteringCountries: boolean;
  ignoredCountries: Set<string>;
  ignoringCountries: boolean;

  selectedCustomers: Set<number>;
  filteringCustomers: boolean;
  ignoredCustomers: Set<number>;
  ignoringCustomers: boolean;

  selectedLicenseKeys: Map<string, LicenseKey>;
  filteringLicenseKeys: boolean;
  ignoredLicenseKeys: Map<string, LicenseKey>;
  ignoringLicenseKeys: boolean;

  onFilterCountries: () => any;
  onFilterCustomers: () => any;
  onFilterLicenseKeys: () => any;

  onIgnoreCountries: () => any;
  onIgnoreCustomers: () => any;
  onIgnoreLicenseKeys: () => any;

  onAnomalyChange: (id: string) => any;
  onCountryClick: (whatamisupposedtowriteherequestionmark: { id: string }) => any;
  onCustomerClick: (whatamisupposedtowriteherequestionmark: number) => any;
  onLicenseKeyClick: (licenseKey: LicenseKey) => any;

  countryTooltip: (code: string) => string;
  customerTooltip: (id: number) => string;
  licenseKeyTooltip: (id: LicenseKey) => string;

  selectMillisecondsAgo: (n: number) => any;
  resetTimeRange: () => any;
}

function FilterPane(props: Props) {
  return (
    <>
    <div className="row">
      <div className="ml-3">
        <select name="pets" id="pet-select" value={props.selectedAnomaly} onChange={e => props.onAnomalyChange(e.target.value)}>
            <option value="-1">Choose a suspected anomaly...</option>
            {props.allAnomalies.map(x => (
              <option value={x.id}>Suspected anomaly: {x.start.toLocaleString()} - {x.end.toLocaleString()}</option>
            ))}
        </select>
      </div>

      <div className="col-auto ml-auto">
        <button className="btn btn-sm" type="button" data-toggle="collapse" data-target="#filters" aria-expanded="false" aria-controls="filters">
          Show/hide filters
        </button>
      </div>
    </div>

    <div className="row">
      <div className="container-fluid collapse pt-3" id="filters">
        <div className="row">
          <div className="col-6">
              <SelectedLicenseKeys
                  itemType="keys"
                  filtering={props.filteringLicenseKeys}
                  selected={props.selectedLicenseKeys}
                  onItemClick={props.onLicenseKeyClick}
                  onFilter={props.onFilterLicenseKeys}
                  tooltip={props.licenseKeyTooltip}
                  description={['Selected', 'Filter']}
              />
          </div>
          <div className="col-6">
              <SelectedLicenseKeys
                  itemType="keys"
                  filtering={props.ignoringLicenseKeys}
                  selected={props.ignoredLicenseKeys}
                  onItemClick={props.onLicenseKeyClick}
                  onFilter={props.onIgnoreLicenseKeys}
                  tooltip={props.licenseKeyTooltip}
                  description={['Ignored', 'Ignore']}
              />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
              <Selected
                  itemType="customers"
                  filtering={props.filteringCustomers}
                  selected={props.selectedCustomers}
                  onItemClick={props.onCustomerClick}
                  onFilter={props.onFilterCustomers}
                  tooltip={props.customerTooltip}
                  description={['Selected', 'Filter']}
              />
          </div>
          <div className="col-6">
              <Selected
                  itemType="customers"
                  filtering={props.ignoringCustomers}
                  selected={props.ignoredCustomers}
                  onItemClick={props.onCustomerClick}
                  onFilter={props.onIgnoreCustomers}
                  tooltip={props.customerTooltip}
                  description={['Ignored', 'Ignore']}
              />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
              <Selected
                  itemType="countries"
                  filtering={props.filteringCountries}
                  selected={props.selectedCountries}
                  onItemClick={x => props.onCountryClick({ id: x })}
                  onFilter={props.onFilterCountries}
                  tooltip={props.countryTooltip}
                  description={['Selected', 'Filter']}
              />
          </div>
          <div className="col-6">
              <Selected
                  itemType="countries"
                  filtering={props.ignoringCountries}
                  selected={props.ignoredCountries}
                  onItemClick={x => props.onCountryClick({ id: x })}
                  onFilter={props.onIgnoreCountries}
                  tooltip={props.countryTooltip}
                  description={['Ignored', 'Ignore']}
              />
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default FilterPane;

/*
function SelectedTimeRange(props: { timeRange?: [Date, Date] }) {
  let text = <span>Showing full history</span>;

  const timeRange = props.timeRange;
  if (timeRange) { text = <span>Showing from {timeRange[0].toLocaleString()} to {timeRange[1].toLocaleString()}</span>; }

  return (<div>{text}</div>);
}
*/

function Selected<T extends Object>(props: { itemType: string, filtering: boolean, selected: Set<T>, onItemClick: (arg: T) => any, onFilter: () => any, tooltip: (arg: T) => string, description: [string, string] }) {
  let className = 'btn btn-outline-primary';
  if (props.filtering) { className = 'btn btn-primary'; }

//                onClick={props.onFilter}>
//             <div onClick={(e) => props.onSelectedCountryClick(country)}
//                   style={{marginRight: 4, display: "inline"}}
//                   key={country.properties.name}
//                   >
//             </div>
  const rowArray = Array.from(props.selected.values());

  return (
        <>
          <button type={'button'} className={className} style={{marginRight: 4}} onClick={props.onFilter}>
            {props.description[1]}
          </button>
          {props.description[0]} {props.itemType}:
          <ul className="list-inline">
          {rowArray.map(x => (
             <li className="list-inline-item" onClick={e => props.onItemClick(x)} key={x.toString()} title={props.tooltip(x)}>
               <>
               {x}
               <button type="button" className="close" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
               </button>
               </>
             </li>
          ))}
          </ul>
        </>
  );
}

function SelectedLicenseKeys(props: { itemType: string, filtering: boolean, selected: Map<string, LicenseKey>, onItemClick: (arg: LicenseKey) => any, onFilter: () => any, tooltip: (arg: LicenseKey) => string, description: [string, string] }) {
  let className = 'btn btn-outline-primary';
  if (props.filtering) { className = 'btn btn-primary'; }

//                onClick={props.onFilter}>
//             <div onClick={(e) => props.onSelectedCountryClick(country)}
//                   style={{marginRight: 4, display: "inline"}}
//                   key={country.properties.name}
//                   >
//             </div>
  const rowArray = Array.from(props.selected.values());

  return (
        <>
          <button type={'button'} className={className} style={{marginRight: 4}} onClick={props.onFilter}>
            {props.description[1]}
          </button>
          {props.description[0]} {props.itemType}:
          <ul className="list-inline">
          {rowArray.map(x => (
             <li className="list-inline-item" onClick={e => props.onItemClick(x)} key={x.productId + ',' + x.licenseKeyId} title={props.tooltip(x)}>
               {x.licenseKeyId}
               <button type="button" className="close" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
               </button>
             </li>
          ))}
          </ul>
        </>
  );
}
