import * as React from 'react';
import WorldMap from './WorldMap';
import { CountrySummary } from './Analytics';

export interface State {
  tab: string;
}

export interface Props {
  countrySummaries: Map<string, CountrySummary>;
  onCountryClick: (arg: { id: string }) => any;
}

class SelectorWorldMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.selectTab = this.selectTab.bind(this);

    this.state = { tab: 'requests' };
  }

  selectTab(tab: string) {
    this.setState({tab: tab});
  }

  render() {
    let countryValue = { 'requests':      x => x.requests
                       , 'activeKeys':    x => x.activeKeys
                       , 'activeDevices': x => x.activeDevices
                       };
    let colourStart  = { 'requests':      '#EFEFFF'
                       , 'activeKeys':    '#EFEFFF'
                       , 'activeDevices': '#EFEFFF'
                       };
    let colourEnd    = { 'requests':      '#02386F'
                       , 'activeKeys':    '#02386F'
                       , 'activeDevices': '#02386F'
                       };

    let reqClass  = 'nav-link';
    let akeyClass = 'nav-link';
    let adevClass = 'nav-link';

    switch (this.state.tab) {
    case 'requests':
      reqClass = 'nav-link active';
      break;
    case 'activeKeys':
      akeyClass = 'nav-link active';
      break;
    case 'activeDevices':
      adevClass = 'nav-link active';
      break;
    default:
      break;
    }

    return (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                  <li className="nav-item"><a className={reqClass} onClick={e => this.selectTab('requests')}>Requests</a></li>
                  <li className="nav-item"><a className={akeyClass} onClick={e => this.selectTab('activeKeys')}>Active keys</a></li>
                  <li className="nav-item"><a className={adevClass} onClick={e => this.selectTab('activeDevices')}>Active devices</a></li>
                <li className="nav-item ml-auto"><span className="glyphicon glyphicon-question-sign" style={{color: '#220999'}} data-container="body" data-toggle="popover" data-trigger="hover" data-placement="left" data-html="true" data-content="<p><b>Requests</b> show the total number of activate or deactivate requests recieved by our api.</p><p><b>Active keys</b> shows the number of unique license keys involved in these requests.</p><p><b>Active devices</b> shows the number of unique devices involved in these requests, where a device is defined as a (license key, machine code) pair.</p>" /></li>
                </ul>

              </div>
              <div className="card-body">
                  <WorldMap
                      countrySummaries={this.props.countrySummaries}
                      onCountryClick={this.props.onCountryClick}
                      countryValue={countryValue[this.state.tab]}
                      colourStart={colourStart[this.state.tab]}
                      colourEnd={colourEnd[this.state.tab]}
                  />
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default SelectorWorldMap;
